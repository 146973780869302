<template>
  <v-app>
    <v-app-bar v-if="!$route.meta.hideNavbar" clipped-left 
    app color="red accent-4" dark shrink-on-scroll fade-img-on-scroll flat
    :src="require('./assets/barra-pasto.jpg')"
    >
       <v-menu
      :nudge-width="150"
      offset-x
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
      </template>
     <v-list  nav shaped>
       <v-list-item link to="/home">
       <v-list-item-icon><v-icon>mdi-file-edit-outline</v-icon></v-list-item-icon>
        Registro
      </v-list-item>
      <v-list-item link to="/entregar">
       <v-list-item-icon><v-icon>mdi-file-check-outline</v-icon></v-list-item-icon>
        Entrega
      </v-list-item>
      <v-list-item link to="/report">
       <v-list-item-icon><v-icon>mdi-file-find-outline</v-icon></v-list-item-icon>
        Reporte
      </v-list-item>
      <v-list-item v-if="verrole" link to="/admin">
       <v-list-item-icon><v-icon>mdi-cogs</v-icon></v-list-item-icon>
        Config
      </v-list-item>
      <v-list-item link @click="logOut()">
       <v-list-item-icon><v-icon>mdi-account-off-outline</v-icon></v-list-item-icon>
        Salir
      </v-list-item>
      </v-list>
     </v-menu> 
    <v-col align-self="center"> 
    <v-row class="" align="center">
     <span class="tittleFont">
        Registro entrega implementos de seguridad
     </span>
    </v-row></v-col>
      <v-img
      contain
      max-width="15%"
      max-height="100%"
      :src="require('./assets/logo.svg')"
    ></v-img> 
    </v-app-bar>
    
    <v-main>
      <router-view />
    </v-main>
    <v-footer color="red accent-4" dark padless>
    <v-col class="text-center" cols="12">
      <strong>Ariztía - Depto. Mantenimiento</strong>
      <br>{{ new Date().getFullYear() }} — v2.1.3_β — <a href="mailto:pegutierrez@ariztia.com?subject=Contacto REIS" style="color:white;">@BenAliSoft</a>
    </v-col>
  </v-footer>
  <!-- α -->
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data:() => ({
      verrole : undefined
    }),
    watch: {
      '$store.state.auth.status.loggedIn': function () {
        let user = JSON.parse(sessionStorage.getItem('user'))
        if (user) {
          this.verrole = user.roles.includes('ROLE_SUPERVISOR')
        }
      }
    },
    methods: {
      logOut() {
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      }
    },
    mounted() {
      let user = JSON.parse(sessionStorage.getItem('user'))
      if (!user) {
        this.$router.push('/login');
      }
    }
};
</script>

<style>
.tittleFont {
  font-family: 'Zen Dots', cursive;
  font-size: 90%;
  color: lemonchiffon;
}
</style>