import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router)

export default new Router({
  mode:'history',
  routes: [{
    path: "/",
    alias: "/login",
    name: "login",
    component: () => import("./components/Admin/Login"),
    props: true,
    meta: {
      hideNavbar: true,
    }
  },{
    path: "/home",
    name: "home",
    component: () => import("./components/Home"),
    props: true
  },{
    path: "/report",
    name: "report",
    component: () => import("./components/Report"),
    props: true
  },{
    path:"/entregar",
    name: "entregar",
    component: () => import("./components/reg_entrega"),
    props: true
  },{
    path: "/admin",
    component: () => import("./components/Admin/Admin"),
    children: [{
        path: "",
        alias: "usuarios",
        name: "usuarios",
        props:true,
        component: () => import('./components/Admin/Usuarios')
      }]
  },
]
})